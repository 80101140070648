import { version as appVersion } from './version';
export const environment = {
  production: true,
  appVersion,
  OAUTH_ISSUER:
    'https://login.microsoftonline.com/8beb5712-d5f0-4b77-808b-99696e49684c/v2.0',
  OAUTH_CLIENTID: '5c634f66-16b4-43c1-a610-ba393581c8b9',
  OAUTH_SCOPE:
    'openid email profile offline_access api://5c634f66-16b4-43c1-a610-ba393581c8b9/api',
  OAUTH_TIMEOUT_FACTOR: 0.25,
  BACKEND_URL: 'https://central.hbw-flower.app/api/backend',
  BACKEND_VERSION: 'https://central.hbw-flower.app/api/version',
  BING_MAPS_API_KEY:
    'AvvRH5yGvQqAXN_4msv11zSdybDV48bcpfWGwyiXQT_yi6R9barPcVfmmVyl-1Ih',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDt79HiaQ370GL7oux9C0eMSDOYqjpQBEc',
};
